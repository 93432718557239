import React, { useState, useEffect, useContext } from 'react';
import * as styles from './productTemplate.module.css';

import AdjustItem from './../components/AdjustItem';
import Button from './../components/Button';
import Container from './../components/Container';
import CurrencyFormatter from './../components/CurrencyFormatter';
import Gallery from './../components/Gallery';
import SizeList from './../components/SizeList';
import SwatchList from './../components/SwatchList';
import Layout from './../components/Layout/Layout';

import { generateMockProductData } from './../helpers/mock';
import ProductCardGrid from './../components/ProductCardGrid';
import { navigate } from 'gatsby';

import AddItemNotificationContext from './../context/AddItemNotificationProvider';

import  {useCart} from './../context/cartContext';
import productsData from './../helpers/product.json';


const ProductTemplate = ({ pageContext }) => {
  const { productCode } = pageContext;

  // const { id } = useParams();
  const [sampleProduct, setSampleProduct] = useState(null);
  const [activeSwatch, setActiveSwatch] = useState(null);
  const [activeSize, setActiveSize] = useState(null);
  const [qty, setQty] = useState(0);
  const [isWishlist, setIsWishlist] = useState(false);

  useEffect(() => {
    const product = productsData.find(p => p.productCode === productCode);

    if (product) {
      setSampleProduct(product);
      if (product.colorOptions) {
        setActiveSwatch(product.colorOptions[0]);
      }

      if (product.sizeOptions) {
        setActiveSize(product.sizeOptions[0]);
      }
    } else {
      navigate('/404'); // Redirect to 404 page if product not found
    }
  }, [productCode]);


  const { addToCart } = useCart();

  const ctxAddItemNotification = useContext(AddItemNotificationContext);
  const showNotification = ctxAddItemNotification.showNotification;


  const filteredProducts = productsData.filter(p => p.productCode !== productCode);

  function getRandomSubset(array, subsetSize) {
    const shuffled = array.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, subsetSize);
  }

  const suggestions = getRandomSubset(filteredProducts, 4);

  const handleAddToCartClick = (product) => {
    addToCart({
      id: product.productCode,
      name: product.name,
      color: activeSwatch.title,
      image: product.image,
      price: product.price,
      quantity: 1 // or the quantity you wish to add
    });

    // Optionally, add any other logic you need after adding to the cart, like showing a confirmation
    showNotification();
  };

  if (!sampleProduct) {
    return <div>Loading product...</div>; // Or any other appropriate loading state
  }


  return (
    <Layout>
      <div className={styles.root}>
        <Container size={'large'} spacing={'min'}>
          <div className={styles.content}>
            <div className={styles.gallery}>
              <Gallery images={sampleProduct.gallery} />
            </div>
            <div className={styles.details}>
              <h1>{sampleProduct.name}</h1>

              <p className={styles.information}>
                    {sampleProduct.description}
                  </p>


              <div className={styles.priceContainer}>
                <CurrencyFormatter appendZero amount={sampleProduct.price} />
              </div>

          {/* Render SwatchList only if colorOptions are available */}
          {sampleProduct.colorOptions && sampleProduct.colorOptions.length > 0 && (
            <div>
              <SwatchList
                swatchList={sampleProduct.colorOptions}
                activeSwatch={activeSwatch}
                setActiveSwatch={setActiveSwatch}
              />
            </div>
          )}

        {sampleProduct.sizeOptions && sampleProduct.sizeOptions.length > 0 && (
              <><div className={styles.sizeContainer}>
                  <SizeList
                    sizeList={sampleProduct.sizeOptions}
                    activeSize={activeSize}
                    setActiveSize={setActiveSize} />
                </div><div className={styles.quantityContainer}>
                    <span>Quantity</span>
                    <AdjustItem qty={qty} setQty={setQty} />
                  </div></>
            )}

              <div className={styles.actionContainer}>
                <div className={styles.addToButtonContainer}>
                  <Button
                    onClick={() => {
                      handleAddToCartClick(sampleProduct)
                    }}
                    fullWidth
                    level={'primary'}
                  >
                    Add to Bag
                  </Button>
                </div>

              </div>


            </div>
          </div>
          <div className={styles.suggestionContainer}>
            <h2>You may also like</h2>
            <ProductCardGrid
              spacing
              showSlider
              height={400}
              columns={4}
              data={suggestions}
            />
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default ProductTemplate;
